<template>
    <v-app id="public_page" style="background: #f2f2f7;">

        <!-- Public page header -->
        <div class="app-container">
            <v-toolbar
                dark
                color="#101928"
                shrink-on-scroll
                prominent
                dense
                class="text-center"
            >
                <v-toolbar-title class="text-center pb-4">
                    <p class="mb-0 text-body-2" style="color: #878c93;">
                        <v-icon @click="go_to_previous()" v-if="previous_step" size="20" class="mr-1" style="margin-left: -20px;cursor: pointer;">mdi-arrow-left</v-icon>
                        Step {{ current_step }} to 5
                    </p>
                    <p class="mb-0 font-weight-bold">{{ header_title }}</p>
                </v-toolbar-title>
            </v-toolbar>
        </div>
        <!-- End public page header -->

        <v-card
            width="800"
            class="mx-auto pa-8"
            tile
            elevation="0"
            color="#f2f2f7"
        >
            <!-- Select location -->
            <v-layout row wrap v-if="current_step == 1">
                <v-flex lg4 sm12>
                    <v-select
                        v-model="selected_country"
                        :items="countries"
                        item-text="name"
                        item-value="name"
                        placeholder="Select Country"
                        @change="open_select_city()"
                        solo
                        class="pr-3"
                    ></v-select>
                </v-flex>
                <v-flex lg4 sm12 v-if="selected_country != ''">
                    <v-select
                        v-model="selected_city"
                        :items="cities"
                        item-text="name"
                        item-value="name"
                        placeholder="Select City"
                        solo
                        class="pl-3"
                    ></v-select>
                </v-flex>
                <v-flex lg4 sm12 v-if="selected_city != ''">
                    <v-select
                        v-model="selected_category"
                        :items="branch_category"
                        item-text="id"
                        item-value="id"
                        placeholder="Select Category"
                        @change="getAllBranch()"
                        solo
                        class="pl-3"
                    ></v-select>
                </v-flex>
                <v-divider v-if="locations.length > 0"></v-divider>
                <v-flex lg6 sm12 v-for="(item) in locations" :key="item.id" class="pa-3">
                    <v-card color="white" min-height="85px" class="d-flex align-center pa-6">
                        <v-layout row justify-space-between class="ma-0" style="cursor: pointer;" @click="go_to_next_step('select_service', item.id)">
                            <v-flex xs11 class="text-sm-left">
                                <span class="" :style="{fontWeight: 'bold', fontSize: '17px'}">
                                    {{ item.id }}
                                </span>
                            </v-flex>
                            <v-flex xs1 class="d-flex">
                                <v-icon size="30">mdi-chevron-right</v-icon>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
            <!-- End select location -->

            <div v-if="current_step == 2 || current_step == 3 || current_step == 4 || current_step == 5">
                <v-layout row wrap>
                    <!-- Select services -->
                    <v-flex lg8 sm12 v-if="current_step == 2">
                        <h3>Hair Services خدمات الشعر</h3>
                        <p class="text-subtitle-2 font-weight-regular">Our highly professional team can manage any kind of hairstyling. Unruly or poker straight, curly or wispy; with their styling techniques and expertise; our professionals stylists deliver the perfect cut customized for your face and hair type.</p>

                        <v-card>
                            <v-list class="pa-0">
                                <template v-for="(service, i) in services">
                                    <v-list-item
                                        link
                                        v-if="!service.selected"
                                        :key="'service_'+i"
                                        class="font-weight-medium py-1 px-5 pr-7"
                                    >
                                        <v-list-item-content>
                                            <div class="d-flex flex-row">
                                                <div class="d-flex flex-column justify-center">
                                                    <v-radio-group v-model="current_service" class="mt-0">
                                                        <v-radio
                                                            :value="service"
                                                            color="primary"
                                                            class="mt-0 pt-1 pl-2"
                                                            @click="select_services()"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span class="text-subtitle-2 font-weight-medium mb-1">{{ service.id }}</span>
                                                    <span style="font-size: 13px !important;" class="text-body-2 text--secondary">{{ service.duration+'min' }}</span>
                                                </div>
                                                <div class="d-flex flex-column justify-center ml-auto text-subtitle-2">SAR {{ service.rate }}</div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider :key="'service_div_'+i"></v-divider>
                                </template>
                            </v-list>
                        </v-card>
                    </v-flex>
                    <!-- End select services -->

                    <!-- Select staff -->
                    <v-flex lg8 sm12 v-if="current_step == 3">
                        <v-card>
                            <v-list class="pa-0">
                                <template v-for="(staff, i) in staffs">
                            
                                    <v-list-item
                                        link
                                        :key="'staff'+i"
                                        class="py-1 px-7 font-weight-medium"
                                        @click="select_staff(staff)"
                                    >
                                        <v-list-item-content>
                                            <div class="d-flex flex-row">
                                                <div class="d-flex flex-column justify-center">
                                                    <v-avatar class="mr-3" size="50">
                                                        <v-icon color="#000" class="grey lighten-4" v-if="staff.id != 'No preference'">
                                                            {{ staff.employee_name.charAt(0) }}
                                                        </v-icon>
                                                        <v-icon color="#000" class="grey lighten-4" v-if="staff.id == 'No preference'">
                                                            $vuetify.icons.NoPreferenceSvg
                                                        </v-icon>
                                                    </v-avatar>
                                                </div>
                                                <div class="d-flex flex-column justify-center">
                                                    <span class="text-subtitle-2 font-weight-medium">{{ staff.employee_name }}</span>
                                                    <span style="font-size: 13px !important;" class="text-body-2 text--secondary" v-if="staff.id == 'No preference'">Maximum availability</span>
                                                </div>
                                                <div class="d-flex flex-column justify-center ml-auto text-subtitle-2"><v-icon>mdi-chevron-right</v-icon></div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider :key="'staff_div_'+i"></v-divider>
                                </template>
                            </v-list>
                        </v-card>
                    </v-flex>
                    <!-- End select staff -->

                    <!-- Select time -->
                    <v-flex lg8 sm12 v-if="current_step == 4">
                        <v-card class="pa-4">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="py-5 px-5">
                                        <label style="border-bottom: 1px dashed #747474;cursor: pointer;" class="font-weight-medium"> {{formatDate}} </label>
                                        <v-icon color="#3c3c3c">mdi-chevron-down</v-icon>
                                    </div>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable   
                                    @change="save"                  
                                >
                                </v-date-picker>
                            </v-menu>

                            <v-divider></v-divider>

                            <v-list class="pa-0">
                                <template v-for="(time, i) in time_slots">
                                    <v-list-item
                                        link
                                        :key="'time_'+i"
                                        class="px-7 font-weight-medium"
                                        @click="select_time(time)"
                                    >
                                    <!-- v-if="time.value.getHours() > 9 && time.value.getMinutes() > 55" -->
                                        <v-list-item-content>
                                            <div class="d-flex flex-row">
                                                <div class="d-flex flex-column justify-center">
                                                    <span class="text-subtitle-2 font-weight-medium">{{ time.label }}</span>
                                                </div>
                                                <div class="d-flex flex-column justify-center ml-auto text-subtitle-2"><v-icon>mdi-chevron-right</v-icon></div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider :key="'time_div_'+i"></v-divider>
                                </template>
                            </v-list>
                        </v-card>
                    </v-flex>
                    <!-- End select time -->

                    <!-- Sign up -->
                    <v-flex lg8 sm12 v-if="current_step == 5">
                        <v-card class="pa-4" v-if="!review_info">
                            <v-card-text class="px-5">
                                <div class="d-flex flex-column">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pb-0"
                                        >
                                            <label class="dialog_label">First name</label>
                                            <v-text-field
                                                solo
                                                placeholder="Your first name"
                                                :error="error"
                                                :rules="[rules.required]"
                                                v-model = "first_name"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pb-0 pt-0"
                                        >
                                            <label class="dialog_label">Last name</label>
                                            <v-text-field
                                                solo
                                                placeholder="Your last name"
                                                :error="error"
                                                :rules="[rules.required]"
                                                v-model = "last_name"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pb-0 pt-0"
                                        >
                                            <label class="dialog_label">Email address</label>
                                            <v-text-field
                                                solo
                                                placeholder="Your email address"
                                                :error="error"
                                                :rules="[rules.required]"
                                                v-model="email"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pt-0 pb-0"
                                        >
                                            <label class="dialog_label">Mobile number</label>
                                            <vue-tel-input-vuetify 
                                                class="custom_solo_field" 
                                                v-model="mobile_number" 
                                                placeholder="" 
                                                solo
                                                :error="error"
                                                :rules="[rules.required]"
                                                required
                                                >
                                            </vue-tel-input-vuetify>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    block
                                    large
                                    color="#101928"
                                    dark
                                    @click="submit_customer_info()"
                                >
                                    Submit
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-card class="pa-5 pb-3" v-if="review_info">
                            <h5 class="text-subtitle-1 mb-2 font-weight-medium">Important info from {{ this.selected_location }}</h5>
                            <p class="text-caption" style="line-height: 17px;letter-spacing: 0px !important;">We are proud to serve you, kindly note the following: 1- Please present your Tawakkalna Account. 2- Arrive 10 minutes early to ensure you the best experience. 3- You may bring your shaving stuff with you.</p>

                            <div class="mt-5">
                                <label class="dialog_label text-subtitle-1 font-weight-medium">Add booking notes</label>
                                <p class="text-caption" style="line-height: 17px;letter-spacing: 0px !important;">Include comments or requests about your booking</p>
                                <v-textarea
                                    solo
                                    auto-grow
                                    rows="4"
                                    row-height="27"
                                    v-model="app_notes"
                                ></v-textarea>
                            </div>
                        </v-card>
                    </v-flex>
                    <!-- End sign up -->
                    
                    <!-- Preview -->
                    <v-flex lg4 sm12 v-if="current_step == 2 || current_step == 3 || current_step == 4 || current_step == 5">
                        <v-card class="ml-7">
                            <h5 class="pa-5 py-4 text-subtitle-2 text-center">{{ this.selected_location }}</h5>

                            <v-divider v-if="selected_time != ''"></v-divider>

                            <div v-if="selected_time != ''" class="pa-4 py-2">
                                <div class="pa-2">
                                    <p class="text-subtitle-2 font-weight-medium mb-0">{{ formatDate | display_date }}</p>
                                    <p class="text-body-2 text--secondary mb-0">{{ selected_time.label }}</p>
                                </div>
                            </div>


                            <v-divider></v-divider>     
                            <p v-if="selected_services.length == 0" class="py-6 text-center text-caption mb-0">No services selected yet</p>

                            <div v-else class="pa-4 py-2">
                                <div v-for="(service, i) in selected_services" :key="'sel_serv_'+i" class="pa-2">
                                    <div class="d-flex flex-row">
                                        <div class="d-flex flex-column">
                                            <span class="text-subtitle-2 font-weight-medium">{{ service.id }}</span>
                                            <span style="font-size: 13px !important;" class="text-body-2 text--secondary">{{ service.duration+'min' }}</span>
                                        </div>
                                        <div class="d-flex flex-column ml-auto text-subtitle-2">SAR {{ service.rate }}</div>
                                    </div>
                                </div>
                            </div>

                            <v-divider v-if="current_step == 5"></v-divider>
                            <div v-if="current_step == 5">
                                <v-btn
                                    block
                                    large
                                    color="#101928"
                                    dark
                                    @click="add_new_service()"
                                >
                                    Add New Service
                                </v-btn>
                            </div>
                            <v-divider></v-divider>

                            <v-layout class="px-6 pa-4 text-subtitle-2" justify-space-between>
                                <v-flex>Total</v-flex>
                                <v-flex class="text-right">SAR {{ this.service_total }}</v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                    <!-- End preview -->
                </v-layout>
            </div>

            <div v-if="selected_services.length > 0 && current_step == 2">
                <v-footer fixed class="pa-0" elevation="3">
                    <v-card
                        flat
                        tile
                        width="100%"
                        class="d-flex align-center"
                        min-height="70px"
                    >
                        <div class="ma-auto text-right jsutify-center" style="width:800px;">
                            <v-btn class="mr-6 text-capitalize" dark @click="book_now()">Book now</v-btn>
                        </div>
                    </v-card>
                </v-footer>
            </div>

            <div v-if="review_info && current_step == 5">
                <v-footer fixed class="pa-0" elevation="3">
                    <v-card
                        flat
                        tile
                        width="100%"
                        class="d-flex align-center"
                        min-height="70px"
                    >
                        <div class="ma-auto" style="width:800px;">
                            <v-layout class="px-6 pa-4 text-subtitle-2 justify-space-between">
                                <v-flex>
                                    <p class="mb-0 text--disabled">{{ selected_services.length }} services</p>
                                    <p class="mb-0">SAR {{ this.service_total }}</p>
                                </v-flex>
                                <v-flex class="text-right">
                                    <v-btn class="text-capitalize" dark @click="confirm_appointment()">Confirm</v-btn>
                                </v-flex>
                            </v-layout>                          
                        </div>
                    </v-card>
                </v-footer>
            </div>
        </v-card>
    </v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'
// import getTimes from '../helpers/get_times.js';
export default {
    name: "PublicPage",

    data: () => ({
        countries: [],
        selected_country: 'Saudi Arabia',
        cities: [],
        branch_category:[],
        selected_category:'',
        selected_city: '',
        locations : [],
        selected_location: '',
        header_title : 'Choose a location',
        current_step: 1,
        services : [],
        current_service : '',
        selected_services: [],
        service_total : 0,
        staffs: [
            {id: 'No preference', employee_name: 'No preference'}
        ],
        selected_staff : '',
        menu: false,
        date: new Date().toISOString().substr(0, 10),
        time_slots: [],
        selected_time : '',
        mobile_number : '',
        app_notes : '',
        review_info : false,
        error : false,
        rules: {
            required: value => !!value || 'This field is required.'
        },
        added_new_service : 0,
        all_selected_data : [],
        first_name : '',
        last_name : '',
        email : ''
    }),
    filters: {
        display_date: function (date) {
            return moment(date, ["dddd, D MMMM Y"]).format('D MMMM Y');
        }
    },
    mounted() {
        this.getAllCountry();
        this.open_select_city()
        this.getCategory()
        // this.getAllData();
    },
    computed: {
        // form () {
        //     return {
        //         first_name: this.name,
        //         last_name: this.address,
        //         email_address: this.city,
        //         mobile_number: this.state,
        //     }
        // },
        formatDate() {
            return this.date ? moment(this.date).format('dddd, D MMMM Y') : "";
        },
        previous_step() {
            if(this.current_step == 1) {
                return false;
            } else {
                return true;
            }
        }
    },
    methods : {
        confirm_appointment() {
            let service_data = [];

            // this.add_new_service()

            let start_time = this.selected_time.value
            let end_time = moment(start_time, 'HH:mm')
                            .add(this.current_service.duration, 'minutes')
                            .format('HH:mm')

            this.selected_services.forEach(ele => {

                let appointment_det = { 
                    service_name : ele.service_name, 
                    rate : ele.rate, 
                    duration : ele.duration, 
                    from_time : moment(start_time,'HHmmss').format("HH:mm:ss"), 
                    to_time : moment(end_time,'HHmmss').format("HH:mm:ss"),
                    staff : this.selected_staff.id
                }
                service_data.push(appointment_det);
            })

            if(service_data.length > 0) {
                let data = { 
                    date: this.date,
                    services: service_data,
                    notes : this.app_notes,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    mobile: this.mobile_number,
                    branch: this.selected_location
                }
                const post_data = new FormData();
                post_data.append("appointment_data", JSON.stringify(data))
                CommonApi.post_data('innosoft_salon.api.book_public_appointment', post_data)
                .then(res => {
                    if(res) {
                        alert("Appoitment saved successfully")
                        this.$router.push('/appointment_confirmation/'+res.data.name);
                        // this.$router.push('/checkout/'+res.data.name+'/'+this.$route.query.employeeId);
                    }
                });
            }
        },
        submit_customer_info() {
            this.review_info = true;
            this.header_title = 'Review and confirm';
        },
        add_new_service() {
            this.selected_services.forEach((sel_ser) => {
                this.services.forEach((ser) => {
                    let start_time = this.selected_time.value
                    let end_time = moment(start_time, 'HH:mm')
                                    .add(sel_ser.duration, 'minutes')
                                    .format('HH:mm')
                    sel_ser['staff'] = this.selected_staff.id
                    sel_ser['from_time'] = moment(start_time,'HHmmss').format("HH:mm:ss")
                    sel_ser['to_time'] = moment(end_time,'HHmmss').format("HH:mm:ss")
                    sel_ser['service_count'] = this.added_new_service
                    if(sel_ser.id == ser.id){
                        ser['selected'] = true
                    }
                })
            })
            this.current_service = ''
            this.selected_staff = ''
            this.selected_time = '',
            this.current_step = 2
            this.added_new_service++;
        },
        go_to_previous() {
            this.current_step = this.current_step - 1;
        },
        select_services() {
            if(this.current_service) {
                let objIndex = this.selected_services.findIndex((obj => obj.service_count == this.added_new_service));
                if(objIndex != -1) {
                    this.selected_services.splice(objIndex, 1)
                    // this.selected_services[objIndex]['duration'] = this.current_service.duration;
                    // this.selected_services[objIndex]['id'] = this.current_service.id;
                    // this.selected_services[objIndex]['rate'] = this.current_service.rate;
                    // this.selected_services[objIndex]['service_name'] = this.current_service.service_name;
                }

                let selected_serv_data = this.current_service;
                selected_serv_data['service_count'] = this.added_new_service;
                this.selected_services.push(this.current_service);
                // }
                
                this.service_total = 0;
                this.selected_services.forEach(val => {
                    this.service_total += val.rate;
                })
            }
        },
        book_now() {
            this.header_title = 'Select staff';
            this.current_step = 3;
        },
        async select_staff(staff) {
            this.selected_staff = staff;
            this.header_title = 'Select time';
            this.current_step = 4;
            let params = { staff_id: this.selected_staff.id, date : this.date, branch: this.selected_location };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_staff_timeslot', params)
            .then(res => {
                if (res.status_code == 200) {
                    let times = res.data;
                    this.time_slots = []
                    times.forEach(val => {
                        let time_data = {
                            label : moment(val,'HH:mm:ss').format("hh:mm A"),
                            value : val
                        }
                        
                        this.time_slots.push(time_data);
                    })
                }
            })
        },
        select_time(time) {
            this.selected_time = time;
            this.header_title = 'Customer information';
            this.current_step = 5;
        },
        async getAllCountry() {
            await CommonApi.get_data('innosoft_salon.api.get_country')
            .then(res => {
                if (res.status_code == 200) {
                    this.countries = res.data;
                }
            })
        },
        async open_select_city() {
            let params = { country: this.selected_country };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_city', params)
            .then(res => {
                if (res.status_code == 200) {
                    this.cities = res.data;
                }
            })
        },
        async getAllBranch() {
            let params = { country: this.selected_country,city:this.selected_city, category:this.selected_category};
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_branch_for_public_page',params)
            .then(res => {
                if (res.status_code == 200) {
                    this.locations = res.data;
                }
            })
        },
        async getCategory() {
            let params = { country: this.selected_country,city:this.selected_city };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_category_for_public_page',params)
            .then(res => {
                if (res.status_code == 200) {
                    this.branch_category = res.data;
                }
            })
        },
        go_to_next_step(next_step, value) {
            if(next_step == 'select_service') {
                this.header_title = 'Select services';
                this.current_step = 2;
                this.selected_location = value;
            }
            this.getStaff()
            this.getService()
        },
        async getAllData() {
            let params = { branch: this.selected_location };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_all_data',params)
            .then(res => {
                if (res.status_code == 200) {
                    this.services = res.data.service_list;
                    // this.staffs.push(...res.data.staff_list);
                }
            });
        },
        async getStaff() {
            let params = { branch: this.selected_location };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_public_page_staff',params)
            .then(res => {
                if (res.status_code == 200) {
                    this.staffs = []
                    this.staffs.push({id: 'No preference', employee_name: 'No preference'})
                    this.staffs.push(...res.data);
                }
            });
        },
        async getService() {
            let params = { branch: this.selected_location };
            params = new URLSearchParams(params);
            await CommonApi.get_data('innosoft_salon.api.get_service_public_page_details',params)
            .then(res => {
                if (res.status_code == 200) {
                    this.services = res.data;
                }
            });
        },
        save (date) {
            this.$refs.menu.save(date);
            this.select_staff(this.selected_staff)
        },
        
    }
}
</script>

<style lang="scss">
    .v-toolbar--prominent .v-toolbar__content {
        display: grid;
    }
</style>